import { type ModalTypes } from "~/config/modals";
import { Modal } from "~/components/_common/Modal/Modal";
import * as classes from "./WizardModal.css";

export function WizardModal({
  modalHistory,
  modalType,
  onClose,
  children,
  title,
  titleIcon,
  withCloseButton = true,
  isShort,
  isMenu,
}: {
  modalHistory: ModalTypes[];
  modalType: ModalTypes;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  titleIcon?: React.ReactNode;
  withCloseButton?: boolean;
  isShort?: boolean;
  isMenu?: boolean;
}) {
  const latestModal = modalHistory[modalHistory.length - 1];

  return (
    <Modal
      withCloseButton={withCloseButton}
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened={modalHistory.includes(modalType)}
      aria-hidden={latestModal !== modalType}
      onClose={onClose}
      title={
        <div className={classes.title}>
          {titleIcon}
          <span>{title}</span>
        </div>
      }
      className={classes.container}
      classNames={{
        title: classes.title,
        close: classes.close,
      }}
      isShort={isShort}
      isMenu={isMenu}
    >
      {children}
    </Modal>
  );
}
